/* eslint-disable react/prop-types */
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';

import MenuOpenIcon from '@mui/icons-material/MenuOpen';
// import RotateLeftIcon from '@mui/icons-material/RotateLeft';
// import RotateRightIcon from '@mui/icons-material/RotateRight';
// Import styles of default layout plugin
import {
  Grid,
  // Tooltip,
  Typography,
} from '@mui/material';
import {
  CharacterMap,
  LoadError,
  // RotateDirection,
  OpenFile,
  SetRenderRange,
  SpecialZoomLevel,
  Viewer,
  VisiblePagesRange,
} from '@react-pdf-viewer/core';
// import { downloadPlugin } from '@react-pdf-viewer/download';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
// import { rotatePlugin } from '@react-pdf-viewer/rotate';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
// default layout plugin
import { toolbarPlugin, ToolbarSlot } from '@react-pdf-viewer/toolbar';
import _ from 'lodash';
import React, { useRef, useState } from 'react';

interface pdfProps {
  fileUrl: string;
  fileName?: string;
  from?: string;
  analyticalCandidateName?: string;
  hideDownload?: boolean;
}

const renderError = (error: LoadError) => {
  let message = '';
  switch (error.name) {
    case 'InvalidPDFException':
      message = 'The file is invalid or corrupted';
      break;
    case 'MissingPDFException':
      message = 'The file is missing';
      break;
    case 'UnexpectedResponseException':
      // message = 'Unexpected server response';
      message = 'No File Found';
      break;
    default:
      message = 'Cannot load the file';
      break;
  }

  return (
    <Typography className="pdf-nofile">
      <Typography className="pdf-nofile-text">{message}</Typography>
    </Typography>
  );
};

const PDFViewer: React.FC<any> = (pdfProps: pdfProps) => {
  const viewerRef = useRef<any>(null);
  const { from, fileName, fileUrl, analyticalCandidateName, hideDownload } =
    pdfProps;

  const [sidePanalOpen, setSidePanalOpen] = useState<boolean>(false);
  const toolbarPluginInstance = toolbarPlugin();
  const thumbnailPluginInstance = thumbnailPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: (file: OpenFile) => {
      if (from === 'AnalyticalReport' && !_.isEmpty(analyticalCandidateName)) {
        const baseName = fileName.slice(0, fileName.lastIndexOf('.')); // Start at index 0 to keep the first letter

        const suffix = '_AnalyticalReport.pdf'; // Suffix to append

        const AnalyticalReportFileName = `${baseName}${suffix}`; // Create new file name

        return AnalyticalReportFileName;
      } else if (
        from === 'DocumentDownload' &&
        !_.isEmpty(analyticalCandidateName)
      ) {
        const baseNameWithPdf = analyticalCandidateName.slice(
          0,
          analyticalCandidateName.lastIndexOf('.'),
        );
        return `${baseNameWithPdf}.pdf`;
      } else {
        const baseNameWithPdf = fileName.slice(0, fileName.lastIndexOf('.'));
        return `${baseNameWithPdf}.pdf`;
      }
    },
  });
  // const rotatePluginInstance = rotatePlugin();
  // const { RotatePage } = rotatePluginInstance;
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { Thumbnails } = thumbnailPluginInstance;
  const { Toolbar } = toolbarPluginInstance;
  // const downloadPluginInstance = downloadPlugin();

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { DownloadButton } = getFilePluginInstance;

  const renderToolBar = (props: any) => {
    const { Download, GoToNextPage, GoToPreviousPage, Zoom, ZoomIn, ZoomOut } =
      props;

    return (
      <Grid container>
        <Grid
          md={12}
          sm={12}
          sx={{
            alignItems: 'center',
            display: 'flex',
            // padding: '18px'
            padding: '0px',
          }}>
          <Grid md={3} sx={{ display: 'flex', marginLeft: '20px' }}>
            <MenuOpenIcon
              className="pointer"
              onClick={() => setSidePanalOpen(!sidePanalOpen)}
              style={{ color: '#fff' }}
            />
          </Grid>

          <Grid md={2}>
            <Typography
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}>
              <ZoomOut />
              <Zoom />
              <ZoomIn />
            </Typography>
          </Grid>

          <Grid md={5}>
            <Typography
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}>
              {/* <RotatePage>
              {(props: {
                onRotatePage: (arg0: number, arg1: RotateDirection) => void;
              }) => (
                <Tooltip title={'Rotate left'} arrow>
                  <RotateLeftIcon
                    sx={{
                      '&:hover': {
                        backgroundColor: 'rgb(0 0 0 / 10%)',
                      },
                      color: '#fff!important',
                      cursor: 'pointer',
                      marginRight: '10px',
                    }}
                    onClick={() =>
                      props.onRotatePage(currentPage, RotateDirection.Backward)
                    }
                  />
                </Tooltip>
              )}
            </RotatePage> */}
              {/* <RotatePage>
              {(props: {
                onRotatePage: (arg0: number, arg1: RotateDirection) => void;
              }) => (
                <Tooltip title={'Rotate right'}>
                  <RotateRightIcon
                    sx={{
                      '&:hover': {
                        backgroundColor: 'rgb(0 0 0 / 10%)',
                      },
                      color: '#fff!important',
                      cursor: 'pointer',
                      marginRight: '10px',
                    }}
                    onClick={() =>
                      props.onRotatePage(currentPage, RotateDirection.Forward)
                    }
                  />
                </Tooltip>
              )}
            </RotatePage> */}
              <GoToPreviousPage />
              <GoToNextPage />
            </Typography>
          </Grid>
          {!hideDownload && (
            <Grid md={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  marginRight: '30px',
                }}>
                {/* <Download /> */}
                {/* <Print /> */}
                {!_.isEmpty(fileName) ? <DownloadButton /> : <Download />}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };

  const characterMap: CharacterMap = {
    isCompressed: true,
    // The url has to end with "/"
    url: 'https://unpkg.com/pdfjs-dist@2.6.347/cmaps/',
  };

  const setRenderRange: SetRenderRange = React.useCallback(
    (visiblePagesRange: VisiblePagesRange) => {
      return {
        endPage:
          visiblePagesRange.startPage <= 20
            ? Math.max(20, visiblePagesRange.endPage + 5)
            : visiblePagesRange.endPage + 5,
        startPage:
          visiblePagesRange.endPage <= 20 ? 0 : visiblePagesRange.startPage - 5,
      };
    },
    [],
  );

  return (
    <Typography
      component={'div'}
      className="rpv-core__viewer"
      sx={{
        // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.03) !important',
        display: 'flex',
        flexDirection: 'column',
        // height: '63vh',
        height:
          from === 'viewResume/CandidateList' || from === 'Jobview'
            ? 'calc(100vh - 70px) !important'
            : '100% !important',

        marginTop:
          from === 'viewResume/CandidateList'
            ? '60px !important'
            : from === 'Jobview'
            ? '85px'
            : '',
      }}>
      <Typography
        component={'div'}
        sx={{
          alignItems: 'center',
          backgroundColor: '#0262b9 !important',
          display: 'flex',
          padding: '0px !important',
        }}>
        <Toolbar>
          {(props: ToolbarSlot) => {
            return renderToolBar(props);
          }}
        </Toolbar>
      </Typography>
      <div
        className="rpv-core__viewer"
        style={{
          border: '1px solid rgba(0, 0, 0, 0.3)',
          display: 'flex',
          height: '100%',
        }}>
        <div
          style={{
            borderRight: '1px solid rgba(0, 0, 0, 0.3)',
            display: !sidePanalOpen ? 'none' : '',
            // overflow: 'auto',
            width: '20%',
          }}>
          <Thumbnails />
        </div>
        <Typography
          component={'div'}
          sx={{
            flex: 1,
            overflow: 'hidden',
          }}>
          <Viewer
            fileUrl={fileUrl}
            ref={viewerRef}
            defaultScale={SpecialZoomLevel.PageWidth} // Set initial page width
            // onPageChange={(e: any) => handlePageChange(e)}
            renderError={renderError}
            plugins={[
              toolbarPluginInstance,
              thumbnailPluginInstance,
              pageNavigationPluginInstance,
              // downloadPluginInstance,
              getFilePluginInstance,
              // rotatePluginInstance,
            ]}
            enableSmoothScroll={false}
            setRenderRange={setRenderRange}
            characterMap={characterMap}
          />
        </Typography>
      </div>
    </Typography>
  );
};

export default PDFViewer;
