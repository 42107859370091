import { Grid, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomDatePicker from 'components/DatePicker';
import ProjectSkillsAddSvg from 'components/SvgAsCode/ProjectSkillsAddSvg';
import ProjectSkillsTrashSvg from 'components/SvgAsCode/ProjectSkillsTrashSvg';
import _ from 'lodash';
import React from 'react';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import { GeneralDetails } from 'types';

import { CustomReactTagInput } from './CustomReactTagInput';

type Props = {
  control: UseFormReturn<GeneralDetails>;
  readOnly?: boolean;
};
export const ExperienceInfo: React.FC<Props> = (props: Props) => {
  const { control: formControl, readOnly } = props;
  const { control, formState, setValue, watch, trigger } = formControl;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'experience',
  });

  const handleSkills = (id: number, value: any) => {
    return setValue(`experience.${id}.skillsMatched`, value);
  };

  const addField = () => {
    append({
      employerOrClient: '',
      fromDate: '',
      isCurrentlyWorking: false,
      jobTitle: '',
      location: '',
      project: '',
      skillsMatched: undefined,
      toDate: '',
    }); // Add a new field with a default value
  };
  const removeField = (idx: number) => {
    remove(idx);
  };

  return (
    <Typography component={'div'}>
      {fields && fields.length
        ? fields.map((item, index: number) => (
            <Grid
              container
              spacing={1}
              key={item.id}
              sx={
                fields.length === index + 1
                  ? {}
                  : {
                      borderBottom: '1px solid #ababff',
                      marginBottom: '10px',
                      paddingBottom: '10px',
                    }
              }>
              <Grid item md={2} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  Period From
                  <span className="red-asterisk"> *</span>
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`experience.${index}.fromDate`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomDatePicker
                          className="custom-datepicker"
                          views="MM/YYYY"
                          name={name}
                          onChange={(e) => {
                            onChange(e);
                            trigger(`experience.${index}.fromDate`);
                            if (
                              !_.isEmpty(watch(`experience.${index}.toDate`))
                            ) {
                              trigger(`experience.${index}.fromDate`);
                              trigger(`experience.${index}.toDate`);
                            }
                          }}
                          maxDate={new Date()}
                          value={value}
                          error={
                            formState.errors?.experience?.[index]?.fromDate
                              ?.message
                          }
                          {...ref}
                          disabled={readOnly ? true : false}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={2} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  Period To
                  {item?.isCurrentlyWorking == false && (
                    <span className="red-asterisk"> *</span>
                  )}
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`experience.${index}.toDate`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomDatePicker
                          className="custom-datepicker"
                          views="MM/YYYY"
                          name={name}
                          onChange={(e) => {
                            onChange(e);
                            if (item?.isCurrentlyWorking == false) {
                              trigger(`experience.${index}.toDate`);
                            }
                            if (
                              !_.isEmpty(watch(`experience.${index}.fromDate`))
                            ) {
                              trigger(`experience.${index}.fromDate`);
                              trigger(`experience.${index}.toDate`);
                            }
                          }}
                          value={value}
                          error={
                            formState.errors?.experience?.[index]?.toDate
                              ?.message
                          }
                          {...ref}
                          disabled={readOnly ? true : false}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={3} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  Job Title
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`experience.${index}.jobTitle`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          value={value}
                          name={name}
                          className="custom-Input"
                          ref={ref}
                          onChange={(e) => {
                            onChange(
                              e?.target?.value
                                .replace(/[^a-zA-Z\s]/g, '')
                                .trimStart(),
                            );
                          }}
                          error={
                            formState.errors?.experience?.[index]?.jobTitle
                              ?.message
                          }
                          disabled={readOnly ? true : false}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={3} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  Employer/Client
                  <span className="red-asterisk"> *</span>
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`experience.${index}.employerOrClient`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          value={value}
                          name={name}
                          className="custom-Input"
                          ref={ref}
                          onChange={(e) => {
                            onChange(
                              e?.target?.value
                                .replace(/[^a-zA-Z\s]/g, '')
                                .trimStart(),
                            );
                            formControl.trigger(
                              `experience.${index}.employerOrClient`,
                            );
                          }}
                          error={
                            formState.errors?.experience?.[index]
                              ?.employerOrClient?.message
                          }
                          disabled={readOnly ? true : false}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={2} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  Location
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`experience.${index}.location`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          value={value}
                          name={name}
                          className="custom-Input"
                          ref={ref}
                          onChange={(e) => {
                            onChange(
                              e?.target?.value
                                .replace(/[^a-zA-Z\s]/g, '')
                                .trimStart(),
                            );
                          }}
                          error={
                            formState.errors?.experience?.[index]?.location
                              ?.message
                          }
                          disabled={readOnly ? true : false}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={4} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  Project
                </Typography>
                <Typography component={'div'}>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`experience.${index}.project`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          placeHolder=""
                          value={value}
                          name={name}
                          className="custom-Input"
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(
                              e?.target?.value
                                .replace(/[^a-zA-Z\s]/g, '')
                                .trimStart(),
                            );
                          }}
                          error={
                            formState.errors?.experience?.[index]?.project
                              ?.message
                          }
                          disabled={readOnly ? true : false}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={6.8} sm={12}>
                <Typography component={'label'} className="input-title pb-2">
                  Project Skill
                </Typography>
                <Typography
                  component={'div'}
                  className={
                    readOnly
                      ? 'projectskillcon projectskillconDisable'
                      : 'projectskillcon'
                  }>
                  <Controller
                    control={control}
                    defaultValue=""
                    name={`experience.${index}.skillsMatched`}
                    render={({ field: { onChange, value, name, ref } }) => {
                      if (fields.length - 1 === index) {
                        return (
                          <CustomReactTagInput
                            value={value}
                            error={
                              formState.errors?.experience?.[index]
                                ?.skillsMatched?.message
                            }
                            handleSkills={(value) =>
                              !readOnly ? handleSkills(index, value) : null
                            }
                            lastItem={true}
                            addField={addField}
                            removeField={() =>
                              !readOnly ? removeField(index) : null
                            }
                          />
                        );
                      }
                      return (
                        <CustomReactTagInput
                          value={value}
                          error={
                            formState.errors?.experience?.[index]?.skillsMatched
                              ?.message
                          }
                          handleSkills={(value) => handleSkills(index, value)}
                          addField={addField}
                          removeField={() => removeField(index)}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              {!readOnly && (
                <Grid item md={1.2} sm={12} sx={{ marginLeft: 'auto' }}>
                  <Typography
                    component={'label'}
                    className="input-title pb-2"
                    sx={{ opacity: 0 }}
                  />
                  <Typography
                    component={'div'}
                    className="flex-row"
                    style={{ transform: 'translateY(16px)' }}>
                    {fields.length == 1 && index <= 0 ? null : (
                      <Typography
                        component="div"
                        className="trashicon"
                        onClick={() => {
                          !readOnly && removeField(index);
                        }}>
                        <ProjectSkillsTrashSvg />
                      </Typography>
                    )}
                    {fields.length - 1 === index ? (
                      <Typography
                        component="div"
                        className="trashicon"
                        onClick={() => {
                          if (!readOnly && addField) {
                            addField ? addField() : null;
                          }
                        }}>
                        <ProjectSkillsAddSvg />
                      </Typography>
                    ) : null}
                  </Typography>
                </Grid>
              )}
            </Grid>
          ))
        : null}
    </Typography>
  );
};
